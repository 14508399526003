@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&family=Poppins:wght@400;700&display=swap');

html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a,
a:focus,
a:hover {
    text-decoration: none;
}
/* Fonts and basic css goes here */
html {
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    font-style: normal;
    color: $color-text;
    overflow-x: hidden;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1 {
    font-size: 48px;
}

h2 {
    font-size: 36px;
}

h3 {
    font-size: 28px;
}

h4 {
    font-size: 22px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

.e-summaryrow {
    height: 2.8125rem;
}

.e-float-input {
    color: rgba(0, 0, 0, 0.87);
}

.toaster-element {
    z-index: 99999 !important;
}

.e-toast-container {
    .e-toast {
        &.e-toast-danger {
            background-color: $color-red !important;
        }
    }
}

app-suppliers,
app-company-details,
app-bank-accounts,
app-expense-accounts,
app-tax-setup,
app-edit-company-details,
app-add-bank-accounts,
app-add-expense-accounts,
app-add-tax-setup,
app-login,
app-register,
app-forgot-password,
app-profile,
app-add-new-supplier,
app-create-cheque,
app-batch-printing,
app-verify-user,
app-user,
app-user-dialog,
app-add-bank-accounts,
app-address-form,
app-confirmation-dialog,
app-transactions,
app-transaction-reports,
app-transaction-expense-reports {
    .e-btn {
        font-size: 0.75rem;
        padding: 0.4375rem 0.875rem;
        line-height: unset;
        border-radius: 0.25rem;
    }

    .e-btn.e-outline.e-success {
        border-color: $color-primary !important;
        background-color: $color-primary !important;
        color: $color-white !important;
        cursor: pointer;
    }

    .e-btn.e-outline.e-danger {
        border-color: $color-pink !important;
        background-color: $color-pink !important;
        color: $color-white !important;
        cursor: pointer;
    }
}

app-edit-company-details,
app-add-new-supplier,
app-user-dialog {
    .e-content {
        height: auto !important;
    }
}

app-suppliers,
app-batch-printing,
app-transactions,
app-post-dated-cheques {
    .e-headercell {
        background-color: #f3f3f3 !important;

        span {
            color: $color-text;
            font-weight: 600;
            font-size: 0.875rem;
        }
    }

    .e-rowcell {
        color: $color-secondary !important;

        span {
            color: $color-secondary;
        }
    }
}

.e-headercell,
.e-rowcell {
    font-family: $font-secondary;
}

app-create-cheque,
app-add-new-supplier,
app-batch-printing,
app-edit-company-details,
app-user-dialog,
app-add-bank-accounts,
app-add-expense-accounts,
app-add-tax-setup {
    .form__group {
        &.required {
            .e-float-text {
                &::after {
                    content: ' *';
                    color: red;
                }
            }
        }
    }
}

.ngneat-dialog-backdrop {
    @include respond(phone) {
        padding: 1rem !important;
    }
}

app-tax-setup {
    table {
        tr > td:last-child {
            padding: 0 !important;
        }
    }
}

app-create-cheque .form__top,
app-batch-printing,
app-transaction-reports,
app-transaction-expense-reports {
    .e-dropdownlist .e-control-wrapper,
    .e-multiselect .e-control-wrapper,
    .e-control-wrapper.e-numeric,
    .e-textbox .e-control-wrapper input,
    .e-datepicker .e-control-wrapper {
        border: 1px solid rgba(0, 0, 0, 0.42) !important;
        padding: 0 0.5rem !important;
        border-radius: 0.25rem;
        margin-bottom: 0 !important;

        &.e-disabled {
            border: 1px solid $bg-400 !important;
        }
    }

    .e-datepicker .e-control-wrapper input {
        padding: 0 0.5rem !important;
    }

    .e-dropdownlist .e-control-wrapper input,
    .e-multiselect .e-control-wrapper input,
    .e-control-wrapper.e-numeric input,
    .e-textbox .e-control-wrapper input,
    .e-datepicker .e-control-wrapper input {
        min-height: 2.25rem !important;
        box-sizing: border-box !important;
    }

    .e-float-text {
        top: -0.75rem !important;
    }

    .e-multiselect .e-control-wrapper.e-input-focus {
        &.e-valid-input {
            padding-top: 0.5rem !important;
            padding-bottom: 0.5rem !important;
        }
    }

    .e-multiselect .e-multi-select-wrapper .e-delim-values {
        line-height: 2.375rem;
    }
}

app-transactions,
app-create-cheque {
    .e-numeric.e-control-wrapper.e-input-group .e-input-group-icon {
        display: none;
    }
}

app-bank-account-chart {
    .bank-account-chart__container {
        svg {
            width: 100% !important;
        }
    }
}

app-expense-account-chart {
    .expense-account-chart__container {
        svg {
            width: 100% !important;
        }
    }
}

.e-grid {
    .e-gridheader {
        padding-right: 0 !important;
    }

    .e-gridcontent {
        .e-content {
            overflow-y: auto !important;
        }
    }
}

#transactionsReportGrid {
    &.has-scrollbar {
        .e-gridheader {
            scrollbar-gutter: stable;
        }

        .e-gridcontent {
            scrollbar-gutter: stable;
        }
    }

    .e-gridcontent {
        height: calc(100vh - 32.75rem) !important;
    }

    @supports (overflow-y: overlay) {
        .e-gridcontent {
            overflow-y: overlay;
        }
    }
}

.e-grid .e-gridheader .e-headercell,
.e-grid .e-row .e-rowcell {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv {
    padding: 0 0.75em 0 0.6em;
}

// post-dated-cheques
.currency-column {
    text-align: right;
}

app-create-cheque {
    .e-grid .e-summarycell {
        padding-right: 0.625rem !important;
        padding-left: 0.625rem !important;
    }

    #taxAmount,
    #amount,
    #netAmount {
        .e-numeric {
            & > input:first-child {
                text-align: right;
            }
        }
    }

    table.e-inline-edit {
        tr {
            td {
                height: 4rem;
            }
        }
    }
}

app-batch-printing {
    button {
        padding: 0.125rem 0.5rem !important;
    }
}

app-batch-printing-dialog {
    #chequeList {
        .e-gridcontent {
            .e-content {
                height: 32.5vh !important;
            }
        }
    }
}

app-batch-printing {
    #bankAccount,
    #batchStatus {
        .e-ddl {
            height: 2rem;
            width: 12.5rem !important;
            max-width: 12.5rem !important;
        }
    }

    #createBatchBtn {
        height: 2rem !important;
    }
}

app-user {
    #overviewgrid {
        &.has-scrollbar {
            .e-headercontent,
            .e-headercontent .e-table,
            .e-gridcontent,
            .e-gridcontent .e-table {
                border-right: none !important;
            }

            .e-gridheader {
                scrollbar-gutter: stable;
            }
        }
    }
}
