@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(../../assets/fonts/MaterialIcons-Regular.ttf); /* For IE6-8 */
    src: local('Material Icons'), local('MaterialIcons-Regular'),
        url(../../assets/fonts/MaterialIcons-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Material Icons Outlined';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons Outlined'),
        local('MaterialIconsOutlined-Regular'),
        url(../../assets/fonts/MaterialIconsOutlined-Regular.otf);
}
