@mixin clearfix {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
  0 - 600px: phone
  600 - 900px: Tablet portrait
  900 - 1200px: Tablet landscape
  1440 - 1600px: desktop
  1600 - 1920px: big-desktop
  1920+ - monitor
  
  $breakpoint arguement choices:
  - mobile
  - tab-port
  - tab-land
  - desktop
  - big-desktop
  - monitor
  ORDER: Base + typography > general layout + grid > page layout > components
  1em = 16px
  */
@mixin respond($breakpoint) {
    @if $breakpoint == phone {
        @media only screen and (max-width: 37.5em) {
            @content;
        } //600px
    }
    @if $breakpoint == tab-port {
        @media only screen and (max-width: 56.25em) {
            @content;
        } //900px
    }
    @if $breakpoint == tab-land {
        @media only screen and (max-width: 75em) {
            @content;
        } //1200px
    }
    @if $breakpoint == desktop {
        @media only screen and (min-width: 90em) and (max-width: 100em) {
            @content;
        } //1600
    }
    @if $breakpoint == big-desktop {
        @media only screen and (min-width: 100.0625em) and (max-width: 120em) {
            @content;
        } //1920
    }
    @if $breakpoint == monitor {
        @media only screen and (min-width: 120.0625em) {
            @content;
        } //1920+
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}
