.ec-card {
    background-color: $color-white;
    border: 1px solid #e4e9f2;
    border-radius: 0.25rem;
}

.container {
    max-width: 71.25rem;
    margin: auto;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
    font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.2;
}

/* CSS flex layout - in templates - starts */
.d-flex {
    display: flex;
}

.d-flex-column {
    display: flex;
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-space-between {
    justify-content: space-between;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-end {
    justify-content: flex-end;
}
/* CSS flex layout - in templates - ends */

.material-icons,
.material-icons-outlined {
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
}

.material-icons {
    font-family: 'Material Icons';
}

.material-icons-outlined {
    font-family: 'Material Icons Outlined';
}
