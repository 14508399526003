$color-primary: #11b792;
$color-secondary: #878787;
$color-gray: #b8b8b8;
$color-light: #f4f4f4;
$color-blue: #1b1b4a;
$color-yellow: #f8d57e;
$color-pink: #ea8481;
$color-red: #e60000;
$color-cyan: #75d3d8;
$color-text: #272727;
$color-white: #fff;

$bg-300: #edf1f7;
$bg-400: #e4e9f2;
$bg-600: #8f9bb3;
$bg-800: #222b45;

$profile-bg: #da3a00;

/* Font family */
$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Lato', sans-serif;
